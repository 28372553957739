var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.loading
    ? _c("v-rating", {
        staticClass: "text-center",
        attrs: { length: "5", clearable: "" },
        on: {
          input: function ($event) {
            return _vm.saveFormUserQuestion()
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "item",
              fn: function (props) {
                return [
                  _c(
                    "v-icon",
                    {
                      attrs: {
                        large: "",
                        color: props.isFilled ? "purple darken-4" : "",
                      },
                      on: { click: props.click },
                    },
                    [_vm._v(_vm._s(`mdi-numeric-${props.index + 1}-box`))]
                  ),
                ]
              },
            },
          ],
          null,
          false,
          2779497557
        ),
        model: {
          value: _vm.rating,
          callback: function ($$v) {
            _vm.rating = $$v
          },
          expression: "rating",
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }