var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { "min-height": "800px" } },
    [
      _c(
        "v-tabs",
        {
          attrs: {
            grow: "",
            centered: "",
            "show-arrows": "",
            "background-color": "primary",
            dark: "",
          },
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        [
          _c("v-tab", { attrs: { href: "#tab-1" } }, [
            _vm._v(" SONUÇLANAN FORMLAR"),
          ]),
          _c("v-tab", { attrs: { href: "#tab-2" } }, [
            _vm._v(" AKTİF FORMLAR"),
          ]),
          _c("v-tab", { attrs: { href: "#tab-4" } }, [
            _vm._v(" FORM KARNELERİ"),
          ]),
          _c("v-tab", { staticClass: "d-none", attrs: { href: "#tab-3" } }, [
            _vm._v(" update form"),
          ]),
        ],
        1
      ),
      _c(
        "v-tabs-items",
        {
          attrs: { touchless: "" },
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        [
          _c(
            "v-tab-item",
            { attrs: { value: "tab-1" } },
            [
              _c(
                "v-card",
                { attrs: { flat: "" } },
                [_c("FormResultsList", { staticClass: "mt-2" })],
                1
              ),
            ],
            1
          ),
          _c(
            "v-tab-item",
            { attrs: { value: "tab-2" } },
            [
              _c(
                "v-card",
                { attrs: { flat: "" } },
                [
                  _c(
                    "v-card-text",
                    [
                      _c("FormList", {
                        on: {
                          changeTabEvent: function ($event) {
                            return _vm.changeTab($event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-tab-item",
            { attrs: { value: "tab-3" } },
            [
              _c(
                "v-card",
                { attrs: { flat: "" } },
                [
                  _c(
                    "v-card-text",
                    [
                      _vm.formId > 0
                        ? _c("FormQuestionsPage", {
                            key: _vm.componentKey,
                            attrs: { formId: _vm.formId },
                            on: {
                              close: function ($event) {
                                return _vm.returnList($event)
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-tab-item",
            { attrs: { value: "tab-4" } },
            [
              _c(
                "v-card",
                { attrs: { flat: "" } },
                [_c("v-card-text", [_c("FormAssessmentGroups")], 1)],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }