var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.results.length > 0
        ? _c(
            "v-list",
            { attrs: { subheader: "", "two-line": "", dense: "" } },
            [
              _vm._l(_vm.results, function (item, idx) {
                return [
                  _c(
                    "v-list-item",
                    { key: idx },
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            [
                              _c("v-toolbar-title", [
                                _vm._v(_vm._s(item.Name)),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-subtitle",
                            [
                              _c("v-icon", { attrs: { "x-small": "" } }, [
                                _vm._v("mdi-calendar"),
                              ]),
                              _vm._v(" " + _vm._s(item.Date) + " "),
                              _vm.$vuetify.breakpoint.mobile
                                ? _c(
                                    "v-chip",
                                    {
                                      staticClass: "ml-1",
                                      attrs: {
                                        "x-small": "",
                                        label: "",
                                        color: "info",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " Form Sonucu: " +
                                          _vm._s(item.Result) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      !_vm.$vuetify.breakpoint.mobile
                        ? _c(
                            "v-list-item-action",
                            [
                              _c(
                                "v-chip",
                                { attrs: { color: "info", label: "" } },
                                [_vm._v("Form Sonucu: " + _vm._s(item.Result))]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      item.PointTemplateId == 7
                        ? _c(
                            "v-list-item-action",
                            [
                              _c(
                                "v-btn",
                                {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover.top",
                                      value: "Karne",
                                      expression: "'Karne'",
                                      modifiers: { hover: true, top: true },
                                    },
                                  ],
                                  attrs: {
                                    icon: "",
                                    to: {
                                      name: "form-karnesi",
                                      params: { id: item.FormId },
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", [
                                    _vm._v("mdi-file-document-outline"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _c(
                            "v-list-item-action",
                            [
                              _c(
                                "v-btn",
                                {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover.top",
                                      value: "Karne",
                                      expression: "'Karne'",
                                      modifiers: { hover: true, top: true },
                                    },
                                  ],
                                  attrs: {
                                    icon: "",
                                    to: {
                                      name: "genel-form-karnesi",
                                      params: { id: item.FormId },
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", [
                                    _vm._v("mdi-file-document-check"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                    ],
                    1
                  ),
                  _c("v-divider", { key: item.Id, staticClass: "mx-2" }),
                ]
              }),
            ],
            2
          )
        : _c("v-alert", { staticClass: "m-5", attrs: { type: "warning" } }, [
            _vm._v(" Yayınlanmış form sonucu bulunmamaktadır."),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }